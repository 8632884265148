import React, { Component } from 'react'
import Words from './Words'

class RiskWords extends Component {
  render() {
    return (
      <div className="RiskWords">
        <Words type="risk" />
      </div>
    )
  }
}

export default RiskWords
