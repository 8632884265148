import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

import ApiErrorMessage from '../ApiErrorMessage'
import FormErrorLabel from '../FormErrorLabel'

const propTypes = {
  title: PropTypes.string,
  word: PropTypes.object,
  open: PropTypes.bool,
  isBusy: PropTypes.bool,
  apiError: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

const defaultProps = {
  word: {},
  open: false,
}

class WordEditModal extends Component {
  state = {
    formData: {},
    isFormValid: false,
    isFormModified: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.word, nextProps.word)) {
      this.setState({
        formData: {
          word: _.get(nextProps, 'word.word', ''),
        },
        isFormValid: false,
        isFormModified: false,
      })
    }
  }

  handleFormValid = () => {
    this.setState({
      isFormValid: true,
    })
  }

  handleFormInvalid = () => {
    this.setState({
      isFormValid: false,
    })
  }

  handleFormChange = (formData, isModified) => {
    this.setState({
      isFormModified: isModified,
      formData,
    })
  }

  handleSubmit = () => {
    const { formData } = this.state
    const { onSubmit } = this.props

    if (onSubmit) {
      onSubmit(formData)
    }
  }

  render() {
    const { title, word, open, isBusy, apiError, onClose } = this.props
    const { isFormValid, isFormModified } = this.state

    return (
      <Modal closeIcon className="WordEditModal" size="small" open={open} onClose={onClose} closeOnDimmerClick={false}>
        <Modal.Header>
          {title}
          {_.isEmpty(word) ? 'の作成' : 'の編集'}
        </Modal.Header>

        <Dimmer active={isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          <ApiErrorMessage error={apiError} />

          <Form
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onChange={this.handleFormChange}
            onValidSubmit={this.handleSubmit}
          >
            <Form.Input
              name="word"
              value={_.get(word, 'word', '')}
              label={title}
              placeholder={`${title}を入力してください`}
              required
              validations="maxLength:255"
              validationErrors={{ maxLength: '255 文字以内で入力してください。' }}
              errorLabel={<FormErrorLabel />}
            />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={onClose} />
          <Button
            positive
            content={_.isEmpty(word) ? '保存' : '更新'}
            disabled={!isFormValid || !isFormModified}
            onClick={this.handleSubmit}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

WordEditModal.propTypes = propTypes
WordEditModal.defaultProps = defaultProps

export default WordEditModal
