import React, { Component } from 'react'
import { Button, Dimmer, Divider, Header, Icon, Loader, Menu, Modal, Statistic, Message } from 'semantic-ui-react'
import { Input, Form } from 'formsy-semantic-ui-react'
import { RestrictedWordApi } from 'trill-api-admin-client'
import _ from 'lodash'
import Store from 'store'

import CancelablePromise from '../../../CancelablePromise'
import GetPermission from '../../../GetPermission'

import ApiErrorMessage from '../../../components/ApiErrorMessage'
import FormErrorLabel from '../../../components/FormErrorLabel'
import DataTable from '../../../components/DataTable'

import WordEditModal from '../../../components/RestrictedWords/WordEditModal'
import WordDeleteModal from '../../../components/RestrictedWords/WordDeleteModal'
import WordRestoreModal from '../../../components/RestrictedWords/WordRestoreModal'

import { SEARCH_INPUT_PATTERN } from '../../../constants/pattern'

const wordApi = new RestrictedWordApi()

let getWords
let sendWord
let deleteWord
let restoreWord

const WordStatus = {
  ALL: 'all',
  TRASH: 'trash',
}

const WordDataByType = {
  ng: {
    title: 'NGワード',
    name: 'NGワード名',
    icon: 'dont',
    duplicatedError: {
      header: 'エラーメッセージ',
      message: '既に登録済のNGワードとなります。',
    },
  },
  risk: {
    title: 'リスクワード',
    name: 'リスクワード',
    icon: 'warning sign',
    duplicatedError: {
      header: 'エラーメッセージ',
      message: '既に登録済のリスクワードとなります。',
    },
  },
}

class Words extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,

      permission: GetPermission('restrictedWord'),

      words: [],
      status: WordStatus.ALL,
      isFormWordsSearchValid: false,

      selectedWord: null,
      isWordUpdateModalOpen: false,
      isWordDeleteModalOpen: false,
      isWordRestoreModalOpen: false,
    }

    _.each(WordStatus, status => {
      _.extend(this.state, {
        [status]: {
          totalPages: 0,
          totalItems: 0,
          currentPage: 1,
          itemsPerPage: 10,
          sorting: {},
          filtering: {
            key: 'word',
            value: '',
          },
        },
      })
    })
  }

  componentDidMount() {
    const { type } = this.props
    const pageState = Store.get(`${type}WordsPageState`)

    if (pageState) {
      const words = pageState.words
      const status = WordStatus.ALL
      const tableData = _.get(pageState, status, this.state[status])

      this.setState(
        {
          status,
          words,
          [status]: tableData,
        },
        () => {
          this.retrieveWords()
        },
      )
    } else {
      this.retrieveWords()
    }
  }

  componentWillUnmount() {
    if (!_.isNil(getWords)) {
      getWords.cancel()
    }

    if (!_.isNil(sendWord)) {
      sendWord.cancel()
    }

    if (!_.isNil(deleteWord)) {
      deleteWord.cancel()
    }

    if (!_.isNil(restoreWord)) {
      restoreWord.cancel()
    }
  }

  handleWordsFormSearchValid = () => {
    this.setState({ isFormWordsSearchValid: true })
  }

  handleWordsFormSearchInvalid = () => {
    this.setState({ isFormWordsSearchValid: false })
  }

  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrieveWords()
      this.savePageState()
    })
  }

  handleWordsSearchInputChange = (event, data) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.value = data.value

    this.setState({ [status]: tableData })
  }

  handleWordsSearchButtonClick = () => {
    if (!this.state.isFormWordsSearchValid) {
      return
    }

    this.retrieveWords()
    this.savePageState()
  }

  handleWordUpdateModalOpen = selectedWord => {
    this.setState({
      isWordUpdateModalOpen: true,
      selectedWord,
    })
  }

  handleWordUpdateModalClose = () => {
    this.setState({
      isWordUpdateModalOpen: false,
      selectedWord: null,
      isBusy: false,
      apiError: null,
    })
  }

  handleWordUpdateModalSubmit = formData => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    const wordData = {
      ...formData,
      wordType: this.props.type,
    }

    sendWord = CancelablePromise(
      _.isEmpty(this.state.selectedWord)
        ? wordApi.postRestrictedWord(wordData)
        : wordApi.patchRestrictedWord(this.state.selectedWord.id, { restrictedWordUpdateValues: wordData }),
    )
    sendWord.promise
      .then(() => {
        this.handleWordUpdateModalClose()
        this.retrieveWords()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        const apiError = error.status === 422 ? WordDataByType[this.props.type].duplicatedError : error
        this.setState({
          isBusy: false,
          apiError,
        })
      })
  }

  handleWordDeleteModalOpen = selectedWord => {
    this.setState({
      isWordDeleteModalOpen: true,
      selectedWord,
    })
  }

  handleWordDeleteModalClose = () => {
    this.setState({
      isWordDeleteModalOpen: false,
      selectedWord: null,
      isBusy: false,
      apiError: null,
    })
  }

  handleWordDeleteModalSubmit = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    deleteWord = CancelablePromise(wordApi.deleteRestrictedWord(this.state.selectedWord.id))
    deleteWord.promise
      .then(() => {
        this.handleWordDeleteModalClose()
        this.retrieveWords()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  handleWordRestoreModalOpen = selectedWord => {
    this.setState({
      isWordRestoreModalOpen: true,
      selectedWord,
    })
  }

  handleWordRestoreModalClose = () => {
    this.setState({
      isWordRestoreModalOpen: false,
      selectedWord: null,
      isBusy: false,
      apiError: null,
    })
  }

  handleWordRestoreModalSubmit = () => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    restoreWord = CancelablePromise(wordApi.putRestrictedWord(this.state.selectedWord.id))
    restoreWord.promise
      .then(() => {
        this.handleWordRestoreModalClose()
        this.retrieveWords()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        const apiError = error.status === 422 ? WordDataByType[this.props.type].duplicatedError : error
        this.setState({
          isBusy: false,
          apiError,
        })
      })
  }

  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrieveWords()
      this.savePageState()
    })
  }

  handleDataTableSortChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrieveWords()
      this.savePageState()
    })
  }

  retrieveWords = () => {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getWords = CancelablePromise(wordApi.getRestrictedWords(this.getRequestQuery()))
    getWords.promise
      .then(response => {
        const responseHeader = response.header
        const words = response.data

        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 10), 10)
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

        this.setState({
          isBusy: false,
          words,
          [status]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        tableData.currentPage = 1
        tableData.itemsPerPage = 50
        tableData.totalPage = 0
        tableData.totalItems = 0

        this.setState({
          words: [],
          [status]: tableData,
          isBusy: false,
          apiError: error,
        })
      })
  }

  getRequestQuery = () => {
    // Get the state of the displayed table data
    const status = this.state.status
    const tableData = this.state[status]

    // Calculate the total page by dividing the setting tableData.totalItems by the setting tableData.itemsPerPage
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    const itemsPerPage = tableData.itemsPerPage

    // Search params
    const filtering = []
    filtering.push(`wordType = '${this.props.type}'`)
    filtering.push(`deletedAt IS${_.isEqual(status, WordStatus.TRASH) ? ' NOT ' : ' '}NULL`)

    if (!_.isEmpty(tableData.filtering.value) && tableData.filtering.value.match(SEARCH_INPUT_PATTERN)) {
      const { key, value } = tableData.filtering

      filtering.push(`${key} LIKE "%${value}%"`)
    }

    // Order params
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : '+'

      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    // Returns an object except empty and not number
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  savePageState() {
    const { type } = this.props
    const { status, words } = this.state
    const tableData = this.state[status]
    const pageState = {
      words,
      status,
      [status]: tableData,
    }

    Store.set(`${type}WordsPageState`, pageState)
  }

  render() {
    const wordType = this.props.type
    const wordData = WordDataByType[wordType]
    const {
      permission,
      isBusy,
      apiError,
      status,
      isFormWordsSearchValid,
      words,
      selectedWord,
      isWordUpdateModalOpen,
      isWordDeleteModalOpen,
      isWordRestoreModalOpen,
    } = this.state
    const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, hasRestorePermission } = permission
    const tableData = this.state[status]
    const hasModalBeShown = isWordUpdateModalOpen || isWordDeleteModalOpen || isWordRestoreModalOpen

    return (
      <div className="Words">
        <Header as="h1">
          <Icon name={wordData.icon} />

          <Header.Content>{wordData.title}</Header.Content>
        </Header>

        <Dimmer active={hasModalBeShown ? false : isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={WordStatus.ALL}
            active={_.isEqual(status, WordStatus.ALL)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={WordStatus.TRASH}
            active={_.isEqual(status, WordStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        <Menu secondary floated="right">
          <Menu.Item>
            <Button
              disabled={!hasCreatePermission}
              primary
              content="作成"
              icon="write"
              labelPosition="right"
              onClick={() => {
                this.handleWordUpdateModalOpen({})
              }}
            />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        <Form onValid={this.handleWordsFormSearchValid} onInvalid={this.handleWordsFormSearchInvalid}>
          <Form.Group>
            <Form.Field width={8}>
              <Input
                name="words-search-text"
                type="text"
                action
                placeholder={`${wordData.name}で検索`}
                value={tableData.filtering.value}
                onChange={this.handleWordsSearchInputChange}
                validations={{ matchRegexp: SEARCH_INPUT_PATTERN }}
                validationErrors={{ matchRegexp: 'キーワードに不正な記号があるため検索できません' }}
                errorLabel={<FormErrorLabel />}
              >
                <input />

                <Button
                  icon="search"
                  onClick={this.handleWordsSearchButtonClick}
                  color="blue"
                  disabled={!isFormWordsSearchValid}
                />
              </Input>
            </Form.Field>
          </Form.Group>
        </Form>

        {(hasModalBeShown || _.isEmpty(apiError)) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        <ApiErrorMessage error={hasModalBeShown ? null : apiError} />

        {!_.isEmpty(words) && (
          <DataTable
            rowKey="id"
            sort={tableData.sorting}
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSortChange}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            items={words}
            columns={[
              {
                label: 'ID',
                align: 'center',
                field: 'id',
                minWidth: '6em',
              },
              {
                label: wordData.name,
                field: 'word',
                minWidth: '20em',
                collapsing: false,
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group secondary>
                    <Button
                      disabled={!hasUpdatePermission}
                      icon="edit"
                      onClick={() => {
                        this.handleWordUpdateModalOpen(item)
                      }}
                    />

                    {!_.isEqual(status, WordStatus.TRASH) && (
                      <Button
                        disabled={!hasDeletePermission}
                        icon="trash alternate outline"
                        onClick={() => {
                          this.handleWordDeleteModalOpen(item)
                        }}
                      />
                    )}

                    {_.isEqual(status, WordStatus.TRASH) && (
                      <Button
                        disabled={!hasRestorePermission}
                        icon="undo"
                        onClick={() => {
                          this.handleWordRestoreModalOpen(item)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {(hasCreatePermission || hasUpdatePermission) && (
          <WordEditModal
            title={wordData.title}
            word={selectedWord}
            open={isWordUpdateModalOpen}
            isBusy={isBusy}
            apiError={apiError}
            onClose={this.handleWordUpdateModalClose}
            onSubmit={this.handleWordUpdateModalSubmit}
          />
        )}

        {hasDeletePermission && (
          <WordDeleteModal
            title={wordData.title}
            word={selectedWord}
            open={isWordDeleteModalOpen}
            isBusy={isBusy}
            apiError={apiError}
            onClose={this.handleWordDeleteModalClose}
            onSubmit={this.handleWordDeleteModalSubmit}
          />
        )}

        {hasRestorePermission && (
          <WordRestoreModal
            title={wordData.title}
            word={selectedWord}
            open={isWordRestoreModalOpen}
            isBusy={isBusy}
            apiError={apiError}
            onClose={this.handleWordRestoreModalClose}
            onSubmit={this.handleWordRestoreModalSubmit}
          />
        )}
      </div>
    )
  }
}

export default Words
