import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Button, Dimmer, Loader, Modal, Message } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

import ApiErrorMessage from '../ApiErrorMessage'

const propTypes = {
  title: PropTypes.string,
  word: PropTypes.object,
  open: PropTypes.bool,
  isBusy: PropTypes.bool,
  apiError: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

const defaultProps = {
  word: {},
  open: false,
}

class WordDeleteModal extends Component {
  state = {
    isFormValid: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.word, nextProps.word)) {
      this.setState({
        isFormValid: false,
      })
    }
  }

  handleFormValid = () => {
    this.setState({
      isFormValid: true,
    })
  }

  handleFormInvalid = () => {
    this.setState({
      isFormValid: false,
    })
  }

  render() {
    const { title, word, open, isBusy, apiError, onClose, onSubmit } = this.props
    const { isFormValid } = this.state

    return (
      <Modal
        className="WordDeleteModal"
        size="small"
        closeIcon
        open={open}
        onClose={onClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header style={{ paddingRight: '3rem' }}>{_.get(word, 'word')}の削除</Modal.Header>

        <Dimmer active={isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          <ApiErrorMessage error={apiError} />

          <Form onValid={this.handleFormValid} onInvalid={this.handleFormInvalid} onValidSubmit={onSubmit}>
            <Message negative>
              <Message.Content>{`確認のため、ゴミ箱へ移動する${title}を入力してください。`}</Message.Content>
            </Message>

            <Form.Input
              required
              name="word"
              label={title}
              placeholder={`削除する${title}を入力してください`}
              validations={{
                isSameWord: (values, value) => value === _.get(word, 'word'),
              }}
            />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={onClose} />
          <Button negative content="ゴミ箱へ移動" disabled={!isFormValid} onClick={onSubmit} />
        </Modal.Actions>
      </Modal>
    )
  }
}

WordDeleteModal.propTypes = propTypes
WordDeleteModal.defaultProps = defaultProps

export default WordDeleteModal
