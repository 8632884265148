import React, { Component } from 'react'
import { Link } from 'react-router'
import { Sidebar, Icon, Menu, Popup, Segment } from 'semantic-ui-react'

import _ from 'lodash'

import store from 'store'

import { UserApi } from 'trill-api-admin-client'

import LogLevel from '../LogLevel'

import logoLight from '../images/logo-light.png'
import logoDarkToolName from '../images/logo-dark-with-tool-name.png'
import GetPermission from '../GetPermission'

import './Main.css'

const userApi = new UserApi()

const logger = LogLevel.getLogger('Main')

const UserRole = {
  USER: 'user',
  VIEWER: 'viewer',
  ANALYST: 'analyst',
  EDITOR: 'editor',
  OWNER: 'owner',
}

const env = {
  development: {
    color: 'green',
    content: '開発環境（DEV）',
  },
  staging: {
    color: 'teal',
    content: 'ステージング環境（STG）',
  },
  production: {
    color: 'red',
    content: '本番環境（PROD）',
  },
  local: {
    color: 'grey',
    content: 'ローカル環境（LOCL）',
  },
}

const defaultSidebarItems = [
  {
    header: '記事',
    items: [
      {
        path: '/articles',
        icon: 'file text',
        label: 'すべての記事',
        scope: 'article',
      },
      {
        path: '/outsourced-articles',
        icon: 'users',
        label: '編集委託記事',
        scope: 'outsourcedArticle',
      },
      {
        path: '/outsourced-articles-csv',
        icon: 'users',
        label: '編集委託記事作成CSV',
        scope: 'article',
      },
    ],
  },
  {
    header: '運用',
    items: [
      {
        path: '/recommended-articles',
        icon: 'thumbs up',
        label: '編集部おすすめ枠',
        scope: 'recommendedArticle',
      },
      {
        path: '/creators',
        icon: 'user',
        label: 'クリエイター',
        scope: 'creator',
      },
      {
        path: '/matomes',
        icon: 'star',
        label: '特集',
        scope: 'matome',
      },
      {
        path: '/personality-quizzes',
        icon: 'heartbeat',
        label: '診断',
        scope: 'personalityQuiz',
      },
      {
        path: '/recommended-tags',
        icon: 'key',
        label: 'キーワード',
        scope: 'recommendedTag',
      },
      {
        path: '/management-categories',
        icon: 'sitemap',
        label: '管理用カテゴリ',
        scope: 'managementCategory',
      },
      {
        path: '/ng-words',
        icon: 'dont',
        label: 'NGワード',
        scope: 'restrictedWord',
      },
      {
        path: '/risk-words',
        icon: 'warning sign',
        label: 'リスクワード',
        scope: 'restrictedWord',
      },
    ],
  },
  {
    header: 'カテゴリ',
    items: [
      {
        path: '/categories',
        icon: 'sitemap',
        label: 'カテゴリ',
        scope: 'category',
      },
    ],
  },
  {
    header: 'タグ',
    items: [
      {
        path: '/tags',
        icon: 'tag',
        label: 'タグ',
        scope: 'tag',
      },
      {
        path: '/tag-groups',
        icon: 'tags',
        label: 'タグ・グループ',
        scope: 'tagGroup',
      },
    ],
  },
  {
    header: 'パートナー',
    items: [
      {
        path: '/cps-media',
        icon: 'rss',
        label: 'CP・メディア',
        scope: 'cp',
      },
      {
        path: '/sponsors',
        icon: 'yen',
        label: 'スポンサー',
        scope: 'sponsor',
      },
    ],
  },
  {
    header: 'マンガ',
    items: [
      {
        path: '/manga-series',
        icon: 'book',
        label: '連載',
        scope: 'series',
      },
      {
        path: '/manga-popular-creators',
        icon: 'users',
        label: '人気クリエイター（TOP）',
        scope: 'mangasPopularCreator',
      },
    ],
  },
  {
    header: 'ゲーム',
    items: [
      {
        path: '/games',
        icon: 'game',
        label: 'ゲーム',
        scope: 'game',
      },
      {
        path: '/game-categories',
        icon: 'block layout',
        label: 'ゲームカテゴリ',
        scope: 'gameCategory',
      },
    ],
  },
  {
    header: 'Google Analytics',
    items: [
      {
        path: '/google-analytics/realtime-report',
        icon: 'chart bar outline',
        label: 'リアルタイムレポート',
        scope: 'realtimeReportGoogleAnalytics',
      },
    ],
  },
  {
    header: 'その他',
    items: [
      {
        path: '/users',
        icon: 'user',
        label: 'ユーザー',
        scope: 'user',
      },
    ],
  },
]

const sidebarItemsByRole = {
  [UserRole.ANALYST]: [
    {
      header: '記事',
      items: [
        {
          label: '編集委託記事',
          icon: 'users',
          path: '/outsourced-articles',
          scope: 'article',
        },
      ],
    },
    {
      header: 'Google Analytics',
      items: [
        {
          label: 'リアルタイムレポート',
          icon: 'chart bar outline',
          path: '/google-analytics/realtime-report',
          scope: 'article',
        },
      ],
    },
  ],
}

const SidenavItems = ({ userRole }) => {
  // eslint-disable-line arrow-body-style
  const buildEnv = _.defaultTo(process.env.REACT_APP_BUILD_ENV, 'local')
  const sidebarItems = _.filter(
    _.map(sidebarItemsByRole[userRole] || _.cloneDeep(defaultSidebarItems), sidebar => ({
      ...sidebar,
      items: _.filter(sidebar.items, item => _.get(GetPermission(item.scope), 'hasReadPermission', false)),
    })),
    sidebar => sidebar.items.length,
  )

  return (
    <div>
      {/* TODO: 仕様が未確定のためコメントアウト */}
      {/* <Link to='/dashboard' className='item' activeClassName='active'>
        <Icon name='dashboard' />
        ダッシュボード
        </Link> */}

      <Segment vertical inverted color={_.get(env, `${buildEnv}.color`)} textAlign="center" size="tiny">
        <b>{_.get(env, `${buildEnv}.content`)}</b>
      </Segment>

      {sidebarItems.map((sidebar, sidebarIndex) => (
        <Menu.Item key={`sidebar_${sidebarIndex}`}>
          <Menu.Header className="Sidenav__SectionHeader">{sidebar.header}</Menu.Header>

          <Menu.Menu>
            {sidebar.items.map((item, itemIndex) => (
              <Menu.Item
                className="Sidenav__SectionItem"
                as={Link}
                to={item.path}
                activeClassName="active"
                key={`sidebar_${sidebarIndex}_item_${itemIndex}`}
              >
                <span>
                  <Icon name={item.icon} />
                </span>
                {item.label}
              </Menu.Item>
            ))}
          </Menu.Menu>
        </Menu.Item>
      ))}
    </div>
  )
}

const ToolbarPopupStyle = {
  padding: '0',
  border: 'none',
  boxShadow: 'none',
}

class Main extends Component {
  state = {
    currentUser: {},
    drawerSidenavVisible: false,
    isPathChanged: false,
  }

  componentDidMount() {
    userApi
      .getCurrentUser()
      .then(response => {
        const user = response.data

        const partialState = { currentUser: { name: user.username, role: user.role } }

        this.setState(partialState)

        const currentUser = { userId: user.id }

        store.set('currentUser', currentUser)
      })
      .catch(error => {
        logger.error('get current user error', error)

        this.setState({
          currentUser: {
            name: 'Anonymous',
            photo: '',
          },
        })
      })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      isPathChanged: this.props.location.pathname !== nextProps.location.pathname,
    })
  }

  handleSidenavToggleClick = () => {
    this.setState({
      drawerSidenavVisible: !this.state.drawerSidenavVisible,
    })
  }

  handleSignOutClick = () => {
    store.remove('authToken')
    store.remove('currentUser')
  }

  render() {
    const { currentUser, drawerSidenavVisible } = this.state

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        isPathChanged: this.state.isPathChanged,
      }),
    )

    const isArticleDetailPage = _.get(this.props, 'location.pathname', '').match(/^\/article(\/\d+)?$/g)

    return (
      <Sidebar.Pushable className={`Main${isArticleDetailPage ? ' Main--ArticleDetail' : ''}`}>
        {/* ツールバー */}
        <Menu borderless fixed="top" icon className="Main__Toolbar">
          <Menu.Item className="Toolbar__SidenavToggle" onClick={this.handleSidenavToggleClick}>
            <Icon name="sidebar" />
          </Menu.Item>

          <Link to="/" className="Toolbar__Logo item">
            <img src={logoDarkToolName} alt="TRILL管理ツール" className="logo" />
          </Link>

          {currentUser && (
            <Menu.Item className="Toolbar__UserToggle">
              <Popup
                on="click"
                position="bottom right"
                basic
                style={ToolbarPopupStyle}
                trigger={
                  <div className="UserToggle__UserInfo">
                    <Icon name="user" />
                    <span>{currentUser.name}</span>
                    <Icon name="dropdown" />
                  </div>
                }
              >
                <Popup.Content>
                  <Menu vertical borderless>
                    <Menu.Item as="a" href="/password-update">
                      <Icon name="key" />
                      パスワード変更
                    </Menu.Item>
                    <Menu.Item onClick={this.handleSignOutClick}>
                      <Icon name="sign out" />
                      サインアウト
                    </Menu.Item>
                  </Menu>
                </Popup.Content>
              </Popup>
            </Menu.Item>
          )}
        </Menu>

        {/* 常に（スクリーン幅が広い時）表示されるサイドバー */}
        <Menu inverted vertical fixed="left" className="Main__Sidenav">
          <SidenavItems userRole={currentUser.role} />
        </Menu>

        {/* スクリーン幅が狭い時に、ツールバーの切り替えボタンで表示される Drawer 形式のサイドバー */}
        <Sidebar
          as={Menu}
          animation="overlay"
          vertical
          inverted
          visible={drawerSidenavVisible}
          className="Main__DrawerSidenav"
        >
          <Link to="/" className="Toolbar__Logo item">
            <img src={logoLight} alt="TRILL" />
          </Link>

          <SidenavItems userRole={currentUser.role} />
        </Sidebar>

        {/* コンテンツ領域 */}
        <Sidebar.Pusher className="Main__Content" dimmed={drawerSidenavVisible}>
          <div className="Main__ContentContainer">
            {/* 中身はルーティングで切り替え */}
            {childrenWithProps}
          </div>
        </Sidebar.Pusher>

        <div className="Main__ContentDimmerArea" onClick={this.handleSidenavToggleClick} />
      </Sidebar.Pushable>
    )
  }
}

export default Main
