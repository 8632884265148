import React, { Component } from 'react'
import Words from './Words'

class NGWords extends Component {
  render() {
    return (
      <div className="NGWords">
        <Words type="ng" />
      </div>
    )
  }
}

export default NGWords
